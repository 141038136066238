import CoreTable from '@/components/Table';
import { Typography } from 'antd';
import React from 'react';
import ImportEmployeeTableColumns from '../../components/ImportTableColums';
import { get } from 'lodash';
import { useCoreContext } from '@/core';
import { CorePagination } from '@/components/Pagination';
import ImportDetailTable from './ImportDetailTable';

const ImportTable = () => {
  const {
    loading,
    dataListImportEmployee,
    methodFilterImportEmployee: { setValue, getValues },
  } = useCoreContext();

  const tableColumns = ImportEmployeeTableColumns();
  return (
    <>
      <CoreTable
        columns={tableColumns}
        dataSource={get(dataListImportEmployee, 'data') ?? []}
        tableName='Center'
        loading={loading}
        expandable={{
          expandedRowRender: (record) => {
            return <ImportDetailTable dataImportEmployee={record.rows} />;
          },
          // rowExpandable: (record) => record.name !== 'Not Expandable',
        }}
      />
      <div className='flex mt-4 justify-between'>
        <Typography.Title level={3}>
          Total:
          {get(dataListImportEmployee, 'total') > 999
            ? '>= 1000'
            : get(dataListImportEmployee, 'total')}
        </Typography.Title>
        <CorePagination
          total={get(dataListImportEmployee, 'total') ?? 0}
          defaultPageSize={20}
          showSizeChanger
          pageSizeOptions={['5', '10', '20']}
          onChange={(page, pageSize) => {
            setValue('page', page);
            setValue('limit', pageSize);
          }}
          current={getValues('page')}
        />
      </div>
    </>
  );
};

export default ImportTable;
