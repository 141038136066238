import { useCoreContext } from '@/core';
import React from 'react';
import { ImportEmployeeRowResult } from '@/domains/import-employee/entities';
import ImportDetailEmployeeTableColumns from '../../components/ImportDetailColumns';
import CoreTable from '@/components/Table';

const ImportDetailTable = ({
  dataImportEmployee,
}: {
  dataImportEmployee: ImportEmployeeRowResult[];
}) => {
  const { loading } = useCoreContext();

  const tableColumns = ImportDetailEmployeeTableColumns();
  return (
    <CoreTable
      columns={tableColumns}
      dataSource={dataImportEmployee}
      tableName='Center'
      loading={loading}
    />
  );
};

export default ImportDetailTable;
