/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import { ImportEmployeeRowResult } from '@/domains/import-employee/entities';
import { Tag } from 'antd';
import { useAppContext } from '@/core';

const ImportDetailEmployeeTableColumns = () => {
  const { t } = useAppContext();

  const status = {
    SUCCESS: 'green',
    PENDING: 'orange',
    FAIL: 'red',
  };
  const tableColumns: ColumnsType<ImportEmployeeRowResult> = [
    {
      title: 'Row',
      render: (item, record, index) => <>{index + 1}</>,
    },
    {
      title: 'Employee code',
      dataIndex: 'employeeCode',
    },
    {
      title: 'Employee name',
      dataIndex: 'fullName',
    },
    {
      title: 'Status',
      render: (_: any, record) => (
        <Tag color={`${status[record.importStatus as unknown as keyof typeof status]}`}>
          {record?.importStatus}
        </Tag>
      ),
    },

    {
      title: 'Note',

      render: (_, record) => <> {record.message ? t(`error.${record.message}`) : ''}</>,
    },
  ];
  return tableColumns;
};

export default ImportDetailEmployeeTableColumns;
