import { Spin } from 'antd';
import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import FindByIdEmployeeViewModel from '../viewmodels/find-by-id.viewmodel';
import { FormEmployee } from './FormEmployee';

export const DrawEmployee: React.FC = () => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');

  const { actionFetchEmployeeById, employee, error, loading } = FindByIdEmployeeViewModel();

  useEffect(() => {
    if (id) {
      actionFetchEmployeeById(id);
    }
  }, [id]);

  return (
    <>
      {
        <div>
          {loading && <Spin />} {error && <>Error</>}
          {employee?.id && <FormEmployee detail={employee} />}
        </div>
      }
    </>
  );
};
