import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import common_en from './static/locales/en/common.json';
import common_vi from './static/locales/vi/common.json';
import error_vi from './static/locales/vi/message_error.json';
import error_en from './static/locales/en/message_error.json';

import { config } from './config';

const resources = {
  en: {
    translations: {
      common: common_en,
      error: error_en,
    },
  },
  vi: {
    translations: {
      common: common_vi,
      error: error_vi,
    },
  },
};
console.log('localStorage.getItem', localStorage.getItem('lang') || config.i18n.defaultLang);

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: localStorage.getItem('lang') || config.i18n.defaultLang,
    ns: ['translations'],
    defaultNS: 'translations',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    fallbackLng: 'en',
  });

export default i18n;
