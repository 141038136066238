import { Authorize, useCoreContext } from '@/core';
import React, { useEffect, useState } from 'react';
import { EmployeeProvider } from '../context';
import EmployeeTable from './List/EmployeeTable';
import EmployeeTableFilter from './List/EmployeeTableFilter';
import { DrawEmployee } from '../components';
import { DrawImportEmployee } from './Import/DrawImportEmployee';
import CoreDrawer from '@/components/CoreDrawer';
import { useSearchParams } from 'react-router-dom';
import { useHandleActionItem } from '@/components/hooks/useHandleActionItem';

export const EmployeeScreen = () => {
  const { open, handleClose, setOpen } = useHandleActionItem();

  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const type = searchParams.get('action');
  useEffect(() => {
    if (id || type) {
      setOpen(true);
    }
  }, [type, id]);
  return (
    <EmployeeProvider>
      <EmployeeTableFilter />
      <EmployeeTable />
      <CoreDrawer
        open={open}
        onClose={handleClose}
        content={type === 'Detail' ? <DrawEmployee /> : <DrawImportEmployee />}
      />
    </EmployeeProvider>
  );
};

export const EmployeeList = Authorize<unknown>(EmployeeScreen);
