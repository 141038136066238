import { defaultsDeep } from 'lodash';
import { overrideConfig } from './override.config';

export interface Config {
  title: string;
  port: number;
  footer: {
    text: string;
  };
  firebase: {
    apiKey: string;
    authDomain: string;
    databaseURL: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
    measurementId: string;
  };

  base: {
    webUrl: string;
    apiUrl: string;
    baseUrl: string;
    uploadEndpoint: string;
    imageEndpoint: string;
    rootEndpoint: string;
    templateSource: string;
  };
  powerbiUrl: {
    link: string;
    group: string;
    report: string;
  };
  regex: {
    email: RegExp;
    phone: RegExp;
    password: RegExp;
  };
  i18n: {
    VI: string;
    EN: string;
    defaultLang: string;
  };
  googleAnalytics: {
    measurementId: string,
  };
  socketService: { main: string };
}

const defaultConfig: Config = {
  title: 'MindX Technology School',
  port: 3006,
  footer: {
    text: 'MindX Technology School 2022',
  },
  firebase: {
    apiKey: 'AIzaSyB-x8gFuVKzbIoB1aYKbG1jrvm8mbZUmkQ',
    authDomain: 'mindx-edu-dev.firebaseapp.com',
    databaseURL: 'https://mindx-edu-dev.firebaseio.com',
    projectId: 'mindx-edu-dev',
    storageBucket: 'mindx-edu-dev.appspot.com',
    messagingSenderId: '592744290445',
    appId: '1:592744290445:web:aa82213d363f9b59c5eac4',
    measurementId: 'G-QPEELWB8Q4',
  },
  base: {
    webUrl: 'https://base.mindx.edu.vn',
    apiUrl: 'https://gate-way.mindx.edu.vn/base-beta-api/graphql',
    baseUrl: 'https://base-api.mindx.edu.vn',
    rootEndpoint: 'https://resources.mindx.edu.vn',
    uploadEndpoint: 'https://resources.mindx.edu.vn/api/v1/resources',
    imageEndpoint: 'https://resources.mindx.edu.vn/uploads/images',
    templateSource: '/uploads/documents/File update TMS -1723549263903-3516.xlsx',
  },
  googleAnalytics: {
    measurementId: 'G-MCRC3T5CRN',
  },
  i18n: {
    VI: 'vi',
    EN: 'en',
    defaultLang: 'vi',
  },
  socketService: {
    main: 'wss://noti.mindx.edu.vn',
  },
  powerbiUrl: {
    link: 'app.powerbi.com',
    group: 'groups',
    report: 'report',
  },

  regex: {
    email: /^[a-z0-9-_.]{0,40}@[a-z0-9]{2,}(.[a-z0-9]{2,4}){1,6}$/,
    phone: /^(((\+84)([0-9]){9})|((\+84(24|28|(2[0-9][0-9])))([0-9]{8})))$/,
    password: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/,
  },
};

export const config = defaultsDeep(overrideConfig, defaultConfig) as Config;
