import { Config } from '@/config/index';

export const overrideConfig: Partial<Config> = {
  firebase: {
    apiKey: 'AIzaSyAh2Au-mk5ci-hN83RUBqj1fsAmCMdvJx4',
    authDomain: 'mindx-edu-prod.firebaseapp.com',
    databaseURL: 'https://mindx-edu-prod.firebaseio.com',
    projectId: 'mindx-edu-prod',
    storageBucket: 'mindx-edu-prod.appspot.com',
    messagingSenderId: '469103925618',
    appId: '1:469103925618:web:06ab79fed8c9edcad2a5eb',
    measurementId: 'G-1GRNYT1ZCN',
  },
  base: {
    webUrl: 'https://base.mindx.edu.vn',
    apiUrl: 'https://gate-way.mindx.edu.vn/base-beta-api/graphql',
    baseUrl: 'https://base-api.mindx.edu.vn',
    rootEndpoint: 'https://resources.mindx.edu.vn',
    uploadEndpoint: 'https://resources.mindx.edu.vn/api/v1/resources',
    imageEndpoint: 'https://resources.mindx.edu.vn/uploads/images',
    templateSource: '/uploads/documents/File update TMS -1723549263903-3516.xlsx',
  },
  powerbiUrl: {
    link: 'app.powerbi.com',
    group: 'groups',
    report: 'report',
  },
};
