import UploadAttachment from '@/components/UploadAttachments';
import { config } from '@/config';
import { useCoreContext } from '@/core';
import { DownloadOutlined } from '@ant-design/icons';
import { useGoogleAnalytics } from '@mx/metric/frontend';
import { Button, Divider, notification } from 'antd';
import Title from 'antd/lib/typography/Title';
import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import ImportTable from './ImportTable';

export const DrawImportEmployee: React.FC = () => {
  const { actionGetListImportEmployee, actionImportEmployee } = useCoreContext();
  const [searchParams] = useSearchParams();
  const { gaEvent } = useGoogleAnalytics();
  const type = searchParams.get('action');

  useEffect(() => {
    if (type === 'Create') {
      actionGetListImportEmployee();
    }
  }, [type]);
  const handleImport = async (response: string) => {
    try {
      await actionImportEmployee({ linkFile: response });
      notification.success({
        message: 'Import Employee success',
        placement: 'bottomRight',
      });
      gaEvent({
        eventName: 'import_employee',
        eventParams: {
          action: 'upload',
        },
      });
    } catch (error) {
      notification.error({
        message: 'Import Employee failed',
        placement: 'bottomRight',
      });
    }
  };
  const handleExportSample = () => {
    const fileUrl = config.base.rootEndpoint + config.base.templateSource;
    const link = document.createElement('a');
    link.href = fileUrl;
    link.setAttribute('sample.xlsx', 'file.xlsx');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <Title level={4}>Import info</Title>
      <Divider />
      <UploadAttachment
        onFinish={handleImport}
        accept='.xlsx'
        setLoading={() => {
          //
        }}
      />
      <Button
        type='primary'
        icon={<DownloadOutlined />}
        className='ml-2'
        onClick={handleExportSample}
      >
        Download template
      </Button>

      <br />
      <br />
      <ImportTable />
    </>
  );
};
